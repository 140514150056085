import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { SEO } from '../components/SEO';
import { Layout } from '../layouts/Layout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="How to use it?" mdxType="SEO" />
    <h1>{`How to use it?`}</h1>
    <h2>{`Install dependencies`}</h2>
    <p>{`The design system library has some peer depencendies, but they should be installed by the "main" package of the monorepo, and accessible to this package thanks to `}<inlineCode parentName="p">{`yarn`}</inlineCode>{` hoisting.`}</p>
    <p>{`run from `}<inlineCode parentName="p">{`./frontend`}</inlineCode>{` :`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`yarn install
`}</code></pre>
    <h2>{`Import component`}</h2>
    <p>{`The design sytem is divided in two bundle :`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`main`}</inlineCode>{`, containing all `}<em parentName="li">{`legacy`}</em>{` modules`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`next`}</inlineCode>{`, produced by the new organisation`}</li>
    </ul>
    <h3>{`Importing a `}<em parentName="h3">{`next`}</em>{` component`}</h3>
    <p>{`You can import from the bundle `}<inlineCode parentName="p">{`@ulule/owl-kit-components/dist/next.esm`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import * as React from 'react'
import { styles as S } from '@ulule/owl-kit-components/dist/next.esm'

function MyReactComponent() {
    return (
      <S.image.Avatar
        src="https://img.ulule.com/display/e730a7983adba81d9b624e4bde22e6400ecc12ff/thumbnail/180x180/avatars/2018/05/29/extellion.130509.130801.jpg"
        type="small"
      />
    )
  }
}
`}</code></pre>
    <h3>{`Importing a `}<em parentName="h3">{`legacy`}</em>{` component`}</h3>
    <p>{`You can directly import it from the module `}<inlineCode parentName="p">{`@ulule/owl-kit-components`}</inlineCode>{`. This allows to keep compatibility with modules create before the migration to the new organisation. You should always prefere importing from `}<inlineCode parentName="p">{`next`}</inlineCode>{` when you can. Components from `}<inlineCode parentName="p">{`legacy`}</inlineCode>{` are a last resort.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import * as React from 'react'
import { UserCard } from '@ulule/owl-kit-components'

class MyReactComponent extends React.Component {
  render() {
    return (
      <UserCard
        name="extellion"
        url="https://fr.ulule.com/extellion/"
        avatar="https://img.ulule.com/display/e730a7983adba81d9b624e4bde22e6400ecc12ff/thumbnail/180x180/avatars/2018/05/29/extellion.130509.130801.jpg"
      />
    )
  }
}
`}</code></pre>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      